import React from "react";

import { makeStyles, createStyles } from "@material-ui/styles";
import { Theme, Container, Typography, Button, Box } from "@material-ui/core";
import { Link } from "gatsby";
import { useSelector } from "react-redux";
import AddIcon from '@material-ui/icons/Add';
import { RootState } from "../../store";

import Img from "gatsby-image";
import { useStaticQuery, graphql } from "gatsby"


const useStyles = makeStyles((theme: Theme) =>
  createStyles(
    {
      rootHeroBar: {
        width: '100%',
        // height: '75vh',
        backgroundColor: theme.palette.primary.main,
        position: 'relative',
        paddingBottom: theme.spacing(8),
        paddingTop: theme.spacing(8),
      },
      content: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      },
      tagLine: {
        marginBottom: theme.spacing(5),
        [ theme.breakpoints.down('xs') ]: {
          fontSize: '1.6rem',
        }
      },
      space: {
        height: theme.spacing(1),
        width: theme.spacing(1),
      },
      btn: {
        width: 200,
      },
      btnWrapper: {
        display: 'flex',
        flexDirection: 'row',
        [ theme.breakpoints.down('sm') ]: {
          flexDirection: 'column',
        }
      },
      imgContainer: {
      },
      mockImg: {
        marginTop: theme.spacing(6),
        borderRadius: theme.spacing(.5),
        boxShadow: theme.shadows[ 1 ],
        height: 653,
        '@media (max-width:1260px)': {
          height: 600,
        },
        '@media (max-width:1160px)': {
          height: 550,
        },
        '@media (max-width:1060px)': {
          height: 500,
        },
        '@media (max-width:960px)': {
          height: 450,
        },
        '@media (max-width:860px)': {
          height: 400,
        },
        '@media (max-width:760px)': {
          height: 350,
        },
        '@media (max-width:660px)': {
          height: 300,
        },
        '@media (max-width:560px)': {
          height: 250,
        },
        '@media (max-width:460px)': {
          height: 200,
        },
        '@media (max-width:375px)': {
          height: 180,
        },
        '@media (max-width:320px)': {
          height: 160,
        },
      },
      appScreenImg: {
        width: '100%',
        marginTop: theme.spacing(6),
        borderRadius: theme.spacing(.5),
        // boxShadow: theme.shadows[ 1 ],
      }
    }
  ),
);

const HeroBar: React.FC = () => {
  const classes = useStyles();
  const user = useSelector((state: RootState) => state.user.user);

  const data = useStaticQuery(graphql`
    query {
        homeImg: file(relativePath: {eq: "app_screen.jpg"}) {
          publicURL
          childImageSharp {
            fluid(maxWidth: 3000) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
    }
  `);

  console.log('data', data);

  return (
    <div className={classes.rootHeroBar}>
      <Container maxWidth="lg" className={classes.content}>
        <Typography variant="h4" component="h1" align="center" className={classes.tagLine}>
          Create Beautiful Screenshots for App Store and Play Store.
        </Typography>

        {
          !user ? (
            <Button className={classes.btn} size="large" variant="contained" color="secondary" component={Link} to="/app/?dialog=1">
              Get Started
            </Button>
          ) : (
              <div className={classes.btnWrapper}>
                <Button className={classes.btn} size="large" variant="contained" color="secondary" component={Link} to="/app/">
                  My Projects
                </Button>
                <div className={classes.space} />
                <Button
                  className={classes.btn}
                  size="large"
                  variant="contained"
                  color="secondary"
                  component={Link}
                  to="/app/?dialog=1"
                  startIcon={<AddIcon />}
                >
                  Create Project
                </Button>
              </div>
            )
        }
        <Img fluid={data.homeImg.childImageSharp.fluid} className={classes.appScreenImg}/>

        {/* <img src="/app_screen.jpg" className={classes.mockImg} alt="Create Beautiful Screenshots for App Store and Play Store." /> */}
      </Container>
    </div>
  );
};

export default HeroBar;