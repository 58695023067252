import React from "react";
import { makeStyles, createStyles } from "@material-ui/styles";
import { Theme, Container, Typography } from "@material-ui/core";
import HeroBar from "../components/HomePage/HeroBar";
import FeatureBox from "../components/HomePage/FeatureBox";

import Layout from '../components/Layout/Layout';

import { useStaticQuery, graphql } from "gatsby"

const useStyles = makeStyles((theme: Theme) => {
  return createStyles(
    {
      root: {
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
      },
      userButton: {
        position: 'absolute',
        top: theme.spacing(2),
        right: theme.spacing(2),
      },
      features: {
        display: 'flex',
        flexDirection: 'column',
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(5),
      },
      tagLine: {
        marginBottom: theme.spacing(10),
      }
    }
  );
});

const IndexPage: React.FC = () => {
  const classes = useStyles();

  const data = useStaticQuery(graphql`
    query {
      imgDevices: file(relativePath: {eq: "feature_devices.png"}) {
        publicURL
        childImageSharp {
          fluid(maxWidth: 1000, webpQuality: 90) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      imgFonts: file(relativePath: {eq: "feature_fonts.png"}) {
        publicURL
        childImageSharp {
          fluid(maxWidth: 1000, webpQuality: 90) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      imgTemplates: file(relativePath: {eq: "feature_templates.png"}) {
        publicURL
        childImageSharp {
          fluid(maxWidth: 1000, webpQuality: 90) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      imgAssets: file(relativePath: {eq: "feature_assets.png"}) {
        publicURL
        childImageSharp {
          fluid(maxWidth: 1000, webpQuality: 70) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  `);

  return (
    <Layout title="Create Beautiful Screenshots for App Store and Play Store">
      <HeroBar />
      <Container maxWidth="lg" className={classes.features}>
        <Typography variant="h4" align="center" className={classes.tagLine}>
          For a limited time, it is FREE!
        </Typography>


        <FeatureBox
          text="Choose from dozens of modern device frames. All with multiple colors and styles."
          imgData={data.imgDevices.childImageSharp.fluid}
        />
        <FeatureBox
          rtl
          text="Upload your custom fonts or choose from hundreds of available fonts."
          imgData={data.imgFonts.childImageSharp.fluid}
        />
        <FeatureBox
          text="Start from ready-made design templates or be creative and design from scratch."
          imgData={data.imgTemplates.childImageSharp.fluid}
        />
        <FeatureBox
          rtl
          text="Hundreds of free backgrounds or vector icons to choose from."
          imgData={data.imgAssets.childImageSharp.fluid}
        />
      </Container>
    </Layout>
  );
};

export default IndexPage;
