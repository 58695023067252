import React from "react";
import clsx from 'clsx';
import { makeStyles, createStyles } from "@material-ui/styles";
import { Theme, Typography } from "@material-ui/core";

import Img, { FluidObject } from "gatsby-image";


const useStyles = makeStyles((theme: Theme) =>
  createStyles(
    {
      rootFeatureBox: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: theme.spacing(5),
        [ theme.breakpoints.down('sm') ]: {
          marginBottom: theme.spacing(3),
          flexDirection: 'column',
        }
      },
      rtl: {
        flexDirection: 'row-reverse',
        [ theme.breakpoints.down('sm') ]: {
          flexDirection: 'column',
        }
      },
      side: {
        flex: 1,
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
      },
      space: {
        width: theme.spacing(10),
        height: theme.spacing(10),
        [ theme.breakpoints.down('sm') ]: {
          width: theme.spacing(5),
          height: theme.spacing(5),
        }
      },
      img: {
        borderRadius: theme.spacing(0.5),
        width: '100%',
        maxWidth: 500,
      }
    }
  ),
);

interface Props {
  text: string;
  imgData: FluidObject;
  rtl?: boolean;
}

const FeatureBox: React.FC<Props> = ({ text, imgData, rtl }) => {

  const classes = useStyles();

  return (
    <div className={clsx(classes.rootFeatureBox, { [ classes.rtl ]: rtl })}>
      <div className={classes.side}>
        <Typography variant="h5">{text}</Typography>
      </div>
      <div className={classes.space} />
      <div className={classes.side}>
        {/* <img src={imgSrc} alt={text} className={classes.img} loading="lazy"/> */}
        <Img fluid={imgData} className={classes.img} />
      </div>
    </div>
  );
};

export default FeatureBox;